import axios from "axios";
import {useEffect, useState} from "react";
import InEvidenzaCarousel from "./InEvidenzaCarousel";
import ScopriOlbiaCarousel from "./ScopriOlbiaCarousel";

const ScopriOlbia = () => {
    return (
        <div className={'h-[59vh]'}>
                <ScopriOlbiaCarousel/>
        </div>
    )
}

export default ScopriOlbia