import React, { useRef, useState, useEffect } from "react";
import { BackButton } from "../components/UI/BackButton";
import LanguageBox from "../components/UI/LanguageSwitcher";
import jsQR from "jsqr";

export const OlbiaCardAdd = () => {
    const [qrValue, setQrValue] = useState("");
    const [isQrCode, setIsQrCode] = useState(false);
    const qrInputRef = useRef();
    const videoContainerRef = useRef();

    useEffect(() => {
        const savedCode = localStorage.getItem("qrCode");
        if (savedCode) {
            setQrValue(savedCode);
            setIsQrCode(true);
            setTimeout(() => {
                handleSubmit();
            }, 200);
        }
    }, []);


    const handleInputChange = (e) => {
        setIsQrCode(false);
        setQrValue(e.target.value);
    };

    const handleScanQR = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: { facingMode: "environment" },
            });
            const video = document.createElement("video");
            videoContainerRef.current.appendChild(video);
            video.srcObject = stream;
            video.play();
            const canvas = document.createElement("canvas");
            const canvasContext = canvas.getContext("2d");
            const scanningInterval = setInterval(() => {
                if (video.readyState === video.HAVE_ENOUGH_DATA) {
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    canvasContext.drawImage(video, 0, 0, canvas.width, canvas.height);
                    const imageData = canvasContext.getImageData(
                        0,
                        0,
                        canvas.width,
                        canvas.height
                    );
                    const code = jsQR(imageData.data, imageData.width, imageData.height);
                    if (code) {
                        clearInterval(scanningInterval);
                        videoContainerRef.current.removeChild(video);
                        stream.getTracks().forEach((track) => track.stop());
                        setIsQrCode(true);
                        setQrValue(code.data);
                        localStorage.setItem("qrCode", code.data);
                        setTimeout(() => {
                            handleSubmit();
                        }, 200);
                    }
                }
            }, 100);
        } catch (error) {
            console.log("Errore durante lo scan QR:", error);
        }
    };
    const handleSubmit = async () => {
        let code;
        if (isQrCode) {
            code = qrValue;
        } else {
            code = qrInputRef.current.value;
        }

        if (!code) {
            alert("Inserisci un codice valido");
            return;
        }

        // Check if the code is saved in localStorage
        const savedCode = localStorage.getItem("qrCode");
        if (savedCode && !isQrCode) {
            code = savedCode;
        }

        try {
            const response = await fetch(
                `https://card.parconazionale5terre.it/ticketing_test?code=${encodeURIComponent(code)}`
                    // `https://card.parconazionale5terre.it/ticketing_test?code=${code}`
            );
            const data = await response.json();
            console.log(data);
            if (data.esito === true) {
                // Clear the saved code from localStorage
                localStorage.setItem("qrCode", data.code);
                localStorage.setItem("qrCodeExpirationTimeStart", data.code_parts.data_inizio);
                localStorage.setItem("qrCodeExpirationTimeEnd", data.code_parts.data_fine);

                window.location.href = "/#/olbia-card/";
            } else {
                alert("La tua card non è valida.");
            }
        } catch (error) {
            console.log("Errore durante la verifica della card:", error);
        }
    };


    return (
        <section className="bg-olbiaBlue h-screen text-left w-full">
            <div className={"flex flex-wrap h-[16vh] content-center relative z-10 p-5"}>
                <div className={"flex flex-wrap items-center"}>
                    <BackButton sfondo={"false"} />
                    <span className={"text-white font-semibold text-[20px] ml-4"}>Olbia Card</span>
                </div>
            </div>
            <div className={"px-1"}>
                <p className={"text-white ml-4 break-words"}>
                    Inserisci il codice che hai ricevuto o scannerizza il QR Code
                </p>
            </div>
            <div className={"flex flex-wrap justify-center items-center mt-5 px-5"}>
                <input
                    type={"text"}
                    placeholder={"Inserisci il codice"}
                    className={"text-left w-full h-[50px] px-5 font-semibold relative z-10 rounded-[10px] shadow-olbiaShadow"}
                    value={qrValue}
                    onChange={handleInputChange}
                    ref={qrInputRef}
                />
            </div>
            <div className={"flex flex-wrap justify-center items-center mt-5 px-5"}>
                <div className="mr-2" ref={videoContainerRef} />
                <button
                    className="bg-white px-3 py-2 rounded-[10px] text-olbiaBlue font-medium w-full mb-5 text-left px-5 h-[51px] text-[20px] font-roboto_slab font-bold"
                    onClick={handleScanQR}
                >
                    Scansiona QR-Code
                </button>
                <button
                    className="bg-white px-3 py-2 rounded-md text-olbiaBlue font-medium h-[51px] w-[100px]"
                    onClick={handleSubmit}
                >
                    Invia
                </button>
            </div>
        </section>
    );
};


