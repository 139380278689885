import reportWebVitals from './reportWebVitals';

import {HashRouter} from "react-router-dom";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        supportedLngs: ['it', 'en'],
        resources: {
            en: {
                translation: {
                    "prezzi": "Prices",
                    "orari": "When",
                    "telefono": "Telephone",
                    "email": "Email",
                    "pec": "PEC",
                    "sito": "Website",
                    "impostazioni": "Settings",
                    "lingua": "Language",
                    "aroundme": "Around Me",
                    "scopriolbia": "Discover Olbia",
                    "tutte": "All",
                    "dovedormire": "Where to sleep",
                    "dovemangiare": "Where to eat",
                    "museiecultura": "Museums and culture",
                    "itinerarituristici": "Tourist itineraries",
                    "saporieshopping": "Flavors and shopping",
                    "news": "News",
                    "rassegna": "Review",
                    "eventi": "Event",
                    "puntidiinteresse": "Points of interest",
                    "cerca": "Search",
                    "selezionaunacategoria": "Select a category",
                    "filtrapercategoria": "Filter",
                    "chiudi": 'Close',
                    "filtra": 'Filter',
                    "cambiolingua1": "Changing the language will restart the app",
                    "cambiolingua2": "Are you sure you want to change the language?",
                    "cambiolingua3": "Confirm",
                    "cambiolingua4": "Cancel",
                    "contattaci": "Contact us",
                    "testocontattaci1": "If you need information we are available every day from Monday to Sunday from 9 am to 11 pm with our telephone service",
                    "testocontattaci2": "Call & Go",
                    "testocontattaci3": "Call us",
                    "testocontattaci4": "If you want to contact us by email you can do so at",
                    "testocontattaci5": "The team Visit Olbia",
                    "crediti": "Credits",
                    "crediti1": "Software design and implementation: ",
                    "vedieventi": "See the events of the review",
                    "vediluoghi": "See the places of the itinerary",
                    "portami": "Take me there",
                    "calendario": "Calendar",
                    'evidenza': 'Highlights',
                    'nessunrisultatotrovatoattornoate': 'No results found around you',
                    'vaiallascheda': 'Go to the card',
                    'mostratutti': 'Show all',
                    'nessunrisultatotrovato': 'No results found',
                    'noncisonoeventiperilgiornoselezionato': 'There are no events for the selected day',
                    'stabilimentibalneari': 'Beach establishments',
                    'mesi': 'Months',
                    'filtraperdata': 'Filter by date',
                    'datainizio': 'Start date',
                    'datafine': 'End date',
                    'applicafiltro': 'Apply filter',
                    'Gennaio': 'January',
                    'Febbraio': 'February',
                    'Marzo': 'March',
                    'Aprile': 'April',
                    'Maggio': 'May',
                    'Giugno': 'June',
                    'Luglio': 'July',
                    'Agosto': 'August',
                    'Settembre': 'September',
                    'Ottobre': 'October',
                    'Novembre': 'November',
                    'Dicembre': 'December',
                    'lunedi': 'Monday',
                    'martedi': 'Tuesday',
                    'mercoledi': 'Wednesday',
                    'giovedi': 'Thursday',
                    'venerdi': 'Friday',
                    'sabato': 'Saturday',
                    'domenica': 'Sunday',
                    'lunedip': 'Mon',
                    'martedip': 'Tue',
                    'mercoledip': 'Wed',
                    'giovedip': 'Thu',
                    'venerdip': 'Fri',
                    'sabatop': 'Sat',
                    'domenicap': 'Sun',

                }

            },
            it: {
                translation: {
                    "prezzi": "Prezzi",
                    "orari": "Orari",
                    "telefono": "Telefono",
                    "email": "Email",
                    "pec": "PEC",
                    "sito": "Sito Web",
                    "impostazioni": "Impostazioni",
                    "lingua": "Lingua",
                    "evidenza": "Evidenza",
                    "aroundme": "Around Me",
                    "scopriolbia": "Scopri Olbia",
                    "tutte": "Tutte",
                    "dovedormire": "Dove dormire",
                    "dovemangiare": "Dove mangiare",
                    "museiecultura": "Musei e cultura",
                    "itinerarituristici": "Itinerari turistici",
                    "saporieshopping": "Sapori e shopping",
                    "news": "News",
                    "rassegna": "Rassegna",
                    "eventi": "Eventi",
                    "puntidiinteresse": "Punti di interesse",
                    "cerca": "Cerca",
                    "selezionaunacategoria": "Seleziona una categoria",
                    "filtrapercategoria": "Filtra per categoria",
                    "chiudi": 'Chiudi',
                    "filtra": 'Filtra',
                    "cambiolingua1": "Cambiando la lingua l'app verrá riavviata",
                    "cambiolingua2": "Sei sicuro di voler cambiare la lingua?",
                    "cambiolingua3": "Conferma",
                    "cambiolingua4": "Annulla",
                    "contattaci": "Contattaci",
                    "testocontattaci1": "Se hai bisogno di informazioni siamo disponibili tutti i giorni dal lunedí alla domenica dalle ore 9 alle ore 23 con il nostro servizio telefonico",
                    "testocontattaci2": "Call & Go",
                    "testocontattaci3": "Chiamaci",
                    "testocontattaci4": "Se vuoi contattarci via mail puoi farlo all'indirizzo",
                    "testocontattaci5": "Il team Visit Olbia",
                    "crediti": "Crediti",
                    "crediti1": "Progettazione e realizzazione software: ",
                    "vedieventi": "Vedi gli eventi della rassegna",
                    "vediluoghi": "Vedi i luoghi dell'itinerario",
                    "portami": "Portami",
                    "calendario": "Calendario",
                    'nessunrisultatotrovatoattornoate': 'Nessun risultato trovato attorno a te',
                    'vaiallascheda': 'Vai alla scheda',
                    'mostratutti': 'Mostra tutti',
                    'nessunrisultatotrovato': 'Nessun risultato trovato',
                    'noncisonoeventiperilgiornoselezionato': 'Non ci sono eventi per il giorno selezionato',
                    'stabilimentibalneari': 'Stabilimenti balneari',
                    'mesi': 'Mesi',
                    'filtraperdata': 'Filtra per data',
                    'datainizio': 'Data inizio',
                    'datafine': 'Data fine',
                    'applicafiltro': 'Applica filtro',
                    'Gennaio': 'Gennaio',
                    'Febbraio': 'Febbraio',
                    'Marzo': 'Marzo',
                    'Aprile': 'Aprile',
                    'Maggio': 'Maggio',
                    'Giugno': 'Giugno',
                    'Luglio': 'Luglio',
                    'Agosto': 'Agosto',
                    'Settembre': 'Settembre',
                    'Ottobre': 'Ottobre',
                    'Novembre': 'Novembre',
                    'Dicembre': 'Dicembre',
                    'lunedi': 'Lunedí',
                    'martedi': 'Martedí',
                    'mercoledi': 'Mercoledí',
                    'giovedi': 'Giovedí',
                    'venerdi': 'Venerdí',
                    'sabato': 'Sabato',
                    'domenica': 'Domenica',
                    'lunedip': 'Lun',
                    'martedip': 'Mar',
                    'mercoledip': 'Mer',
                    'giovedip': 'Gio',
                    'venerdip': 'Ven',
                    'sabatop': 'Sab',
                    'domenicap': 'Dom',




                }
            }
        },
        fallbackLng: "it",
        detection: {
            order: ['localStorage', 'path', 'cookie', 'htmlTag', 'path', 'subdomain'],
            caches:['localStorage', 'cookie'],
        },
    });


const renderReactDom = () => {
    ReactDOM.render(
        <HashRouter>
            <App/>
        </HashRouter>
        , document.getElementById('root'));
};

renderReactDom()
//
// if (window.cordova) {
//     document.addEventListener('deviceready', () => {
//         renderReactDom();
//         if ('geolocation' in navigator) {
//             navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
//                 if (result.state === 'granted') {
//                     console.log('Geolocation permission granted');
//                 } else if (result.state === 'prompt') {
//                     navigator.geolocation.getCurrentPosition(function () {
//                         console.log('Geolocation permission granted');
//                     }, function () {
//                         console.log('Geolocation permission denied');
//                     });
//                 } else {
//                     console.log('Geolocation permission denied');
//                 }
//             });
//         } else {
//             console.log('Geolocation is not supported by this browser');
//         }
//     }, false);
// } else {
//     renderReactDom();
//
//     if ('geolocation' in navigator) {
//         navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
//             if (result.state === 'granted') {
//                 console.log('Geolocation permission granted');
//             } else if (result.state === 'prompt') {
//                 navigator.geolocation.getCurrentPosition(function () {
//                     console.log('Geolocation permission granted');
//                 }, function () {
//                     console.log('Geolocation permission denied');
//                 });
//             } else {
//                 console.log('Geolocation permission denied');
//             }
//         });
//     } else {
//         console.log('Geolocation is not supported by this browser');
//     }
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
