import { useEffect, useRef, useState } from "react";
import axios from "../api";
import olbiaLogo from '../../assets/olbia.svg';
const useAdvertisingVideos = () => {
    const [data, setData] = useState(null);
    const videoRefs = useRef([]);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [isSingleVideo, setIsSingleVideo] = useState(false); // Aggiunto

    useEffect(() => {
        axios
            .get(`https://backend.olbia.bbsitalia.com/api/advertising-video`)
            .then((response) => {
                setData(response.data);
                setIsSingleVideo(response.data.rows.length === 1); // Aggiunto
                console.log("data received:", response.data);
            })
            .catch((error) => console.log(error));
    }, []);

    useEffect(() => {
        const currentVideo = videoRefs.current[currentVideoIndex];
        if (currentVideo) {
            currentVideo.addEventListener("ended", handleVideoEnd);
            currentVideo.play();
            console.log("video started, currentVideoIndex:", currentVideoIndex);
        }

        return () => {
            if (currentVideo) {
                currentVideo.removeEventListener("ended", handleVideoEnd);
                currentVideo.pause();
                console.log("video paused, currentVideoIndex:", currentVideoIndex);
            }
        };
    }, [currentVideoIndex, videoRefs, data]);

    const handleVideoEnd = () => {
        if (!isSingleVideo) { // Aggiunto
            setCurrentVideoIndex((currentVideoIndex + 1) % data.rows.length);
        } else { // Aggiunto
            const currentVideo = videoRefs.current[currentVideoIndex];
            currentVideo.currentTime = 0;
            currentVideo.play();
        }
    };

    return { data, videoRefs, currentVideoIndex };
};



const HeaderTotem = () => {
    const { data, videoRefs, currentVideoIndex } = useAdvertisingVideos();

    return (
        <div className={"bg-olbiaBlue flex flex-wrap h-[20vh] content-center items-center p-8 relative"}>
            <img className={"w-[150px] h-auto m-auto relative z-10"} src={olbiaLogo} alt="Olbia Logo" />
            {data && data.rows.map((item, index) => (
                <video
                    key={index}
                    ref={(element) => (videoRefs.current[index] = element)}
                    src={`https://backend.olbia.bbsitalia.com${item.video}`}
                    autoPlay={index === 0}
                    muted
                    className={"w-full h-full object-cover absolute top-0 left-0 z-auto"}
                    style={{ display: index === currentVideoIndex ? "block" : "none" }}
                />
            ))}
        </div>
    );
};

export default HeaderTotem;
