import {BackButton} from "../components/UI/BackButton";
import React from "react";

export const ComuneETerritorio = () => {
    return (
        <section className="bg-olbiaBlue h-screen text-left w-full">
            <div className={"flex flex-wrap h-[16vh] content-center relative z-10 p-5"}>
                <div className={"flex flex-wrap items-center"}>
                    <BackButton sfondo={"false"} />
                    <span className={"text-white font-semibold text-[20px] ml-4"}>Comune</span>
                </div>

            </div>
            <p className={"text-white font-semibold text-[16px] ml-4"}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl sit amet
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl sit amet
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl sit amet
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl sit amet
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl sit amet
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl sit amet
            </p>
        </section>
    );
};