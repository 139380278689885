// Componente EventiTest

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DateFilter } from './DateFilter';
import { MonthDayFilter, MonthFilter } from './MonthFilter';
import { ShowAllButton } from './ShowAllButton';
import { EventList } from './EventList';
import { BackButton } from "../../components/UI/BackButton";
import { Link } from "react-router-dom";
import list from "../../assets/icons/list.png";
import { useTranslation } from "react-i18next";

function EventiTest() {
    const { t } = useTranslation();
    const [events, setEvents] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [noResults, setNoResults] = useState(false); // Aggiunta dello stato per il messaggio "Nessun risultato trovato"

    const currentLanguage = localStorage.getItem('i18nextLng');

    const fetchEvents = async (page = 0, allEvents = []) => {
        const response = await axios.get(
            `https://backend.olbia.bbsitalia.com/${currentLanguage}/api/eventi?page=${page}`
        );
        const events = response.data.rows.filter(event => {
            if (!event.data_fine) {
                return true; // Include events without an end date
            }
            return new Date(event.data_fine) >= new Date();
        });
        allEvents.push(...events);

        if (page < response.data.pager.total_pages) {
            await fetchEvents(page + 1, allEvents);
        }

        setEvents(allEvents);
        // Set filteredEvents to only include non-expired events
        setFilteredEvents(allEvents.filter(event => new Date(event.data_fine) >= new Date()));
    };

    useEffect(() => {
        fetchEvents();
    }, []);

    useEffect(() => {
        // Check if filteredEvents is empty to show "No results" message
        if (filteredEvents.length === 0) {
            setNoResults(true);
        } else {
            setNoResults(false);
        }
    }, [filteredEvents]);

    return (
        <section>
            <div className={"flex flex-wrap h-[16vh] content-center relative z-0 p-5 bg-olbiaBlue"}>
                <div className={"flex flex-wrap items-center relative w-full"}>
                    <BackButton sfondo={"false"} />
                    <span className={"text-white font-semibold text-[20px] ml-4"}>{t('calendario')}</span>
                    <Link to={'/calendario'} className={'absolute right-0'}>
                        <img src={list} />
                    </Link>
                </div>
            </div>
            <div className={'flex flex-wrap'}>
                <div className="flex  py-5 px-3 h-[65vh] w-[70px]">
                    {/* Left panel with day buttons */}
                    <div className="flex flex-wrap h-full w-[70px] pb-5">
                        <MonthDayFilter events={events} setFilteredEvents={setFilteredEvents} />
                        <ShowAllButton setFilteredEvents={setFilteredEvents} events={events} />
                        <DateFilter events={events} setFilteredEvents={setFilteredEvents} />
                    </div>
                </div>

                <div className="flex  py-5 px-3 h-[80vh]" style={{ width: 'calc(100% - 70px)' }}>
                    <div className="flex-grow h-full overflow-auto">
                        <div className="px-4">
                            <div className="event-column">
                                {noResults ? (
                                    <p>{t('noncisonoeventiperilgiornoselezionato')}</p>
                                ) : (
                                    <EventList events={filteredEvents} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default EventiTest;
